<template>
    <router-view/>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'Kennisbank',
        components: {}
    }
</script>
